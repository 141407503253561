<script setup lang="ts">
import { type MetaPagination } from "@gasparigit/omnia-sdk";

const props = defineProps<{
  meta: MetaPagination | undefined;
}>();

const page = defineModel<number>("page");
const cursor = defineModel<string>("cursor");

const emits = defineEmits(["updatePage", "updateCursor"]);

function isClassicPagination() {
  const meta = props.meta;
  return meta && "total" in meta && "current_page" in meta && "last_page" in meta;
}

function isCursorPagination() {
  const meta = props.meta;
  return meta && ("next_cursor" in meta || "prev_cursor" in meta);
}

watch(page, () => {
  useRouter().push({ query: { ...useRoute().query, page: page.value } });
});
</script>

<template>
  <div class="flex items-center justify-end py-4 space-x-2" v-if="meta && (!isClassicPagination() || meta.last_page > 1)">
    <Pagination
      v-if="isClassicPagination()"
      v-model:page="page"
      v-slot="{ page }"
      :total="meta.total"
      :items-per-page="meta.per_page"
      :sibling-count="1"
      show-edges
      :default-page="meta.current_page"
      @update:page="(p) => $emit('updatePage', p)"
    >
      <PaginationList v-slot="{ items }" class="flex items-center gap-1">
        <PaginationPrev />

        <template v-for="(item, index) in items">
          <PaginationListItem v-if="item.type === 'page'" :key="index" :value="item.value" as-child>
            <Button class="w-10 h-10 p-0" :variant="item.value === page ? 'default' : 'outline'">
              {{ item.value }}
            </Button>
          </PaginationListItem>
          <PaginationEllipsis v-else :key="item.type" :index="index" />
        </template>

        <PaginationNext />
      </PaginationList>
    </Pagination>

    <template v-else-if="isCursorPagination()">
      <Button variant="outline" :disabled="!meta.prev_cursor" @click="(cursor = meta?.prev_cursor), $emit('updateCursor', cursor)">Pagina precedente</Button>
      <Button variant="outline" :disabled="!meta.next_cursor" @click="(cursor = meta?.next_cursor), $emit('updateCursor', cursor)">Pagina successiva</Button>
    </template>
  </div>
</template>
